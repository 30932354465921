<template>
  <div class="pseudo-table" :class="{ withMoreMenu }">
    <div class="pseudo-table-content">
      <div class="pseudo-table--head">
        <TableRow>
          <template #content>
            <slot name="table-head" />
          </template>
        </TableRow>
      </div>
      <div class="pseudo-table--body">
        <slot name="table-body" />
      </div>

      <div v-if="$slots['table-footer']" class="pseudo-table--footer">
        <slot name="table-footer" />
      </div>
    </div>
  </div>
</template>

<script>
import TableRow from "@/components/Table/TableRow";
export default {
  name: "TableView",
  components: { TableRow },
  props: { withMoreMenu: { type: Boolean, default: false } }
};
</script>

<style lang="scss">
.pseudo-table {
  @extend %box;
  position: relative;
  overflow: visible;
  .pseudo-table--body:last-child {
    .pseudo-table-row:last-child {
      border-bottom: {
        left-radius: $border-radius;
        right-radius: $border-radius;
      }
    }
  }

  .user-info.name-only {
    .user-avatar {
      font-size: 2.4rem;
    }
  }

  &.withMoreMenu {
    .pseudo-table--head {
      .pseudo-table-row {
        &:after {
          content: "";
          display: block;
          height: 1px;
          width: $moreBtnWidth;
        }
      }
    }
  }
  &--head {
    border-bottom: 1px solid $border-color;
    overflow: hidden;

    border-top: {
      left-radius: 12px;
      right-radius: 12px;
    }
  }

  &.user-table {
    .pseudo-table--body {
      min-height: 300px;
    }
  }
}
</style>
