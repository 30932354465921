<template>
  <Dialog
    :opened="addCreditPackModalOpened"
    title="New Credit Package"
    @hide="addCreditPackModalOpened = false"
  >
    <form @submit.prevent>
      <div class="row">
        <div class="col-12 mt-3">
          <AppInput
            id="package/title"
            v-model="newPackageTitle"
            input-type="material"
            label="Title"
          />
        </div>
        <div class="col-12 mt-3">
          <AppInput
            id="package/price"
            v-model="newPackagePrice"
            input-type="material"
            label="Package price"
          />
        </div>
        <div class="col-12 mt-3">
          <AppInput
            id="package/Credits"
            v-model="newPackageCredits"
            input-type="material"
            label="Credits"
          />
        </div>
        <div class="col-12 mt-3">
          <label for="package/active" class="d-flex align-items-center">
            <AppCheckbox id="package/active" v-model="newPackageActive" />
            <strong class="sm ms-2">Active</strong>
          </label>
        </div>
      </div>

      <Btn size="xl" type="secondary" class="mt-5">Add Credit Package</Btn>
    </form>
  </Dialog>
  <div class="container">
    <PageMainHeading title="Credit packages">
      <div class="d-flex w-100 justify-content-end page-heading-toolbar">
        <div class="page-heading-toolbar--col">
          <Btn
            size="xl"
            type="secondary"
            @click="addCreditPackModalOpened = true"
          >
            <BtnContent icon="plus" label="Add Package" />
          </Btn>
        </div>
      </div>
    </PageMainHeading>

    <div class="account-wrapper pb-5" style="max-width: 100%; overflow: auto">
      <TableView class="user-table inline-table" :with-more-menu="true">
        <template #table-head>
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="flex-1">
              <TableHeadCell size="xxl" name="Name" />
            </div>

            <div
              class="flex-2 d-flex align-items-center justify-content-end w-100"
            >
              <TableHeadCell size="sm" name="Credits" />
              <TableHeadCell size="md" name="Package Price" />
              <TableHeadCell size="smL" name="Status" />
            </div>
          </div>
        </template>

        <template #table-body>
          <TableRow
            v-for="n of 6"
            :key="n"
            :more-menu="[
              {
                text: 'Edit',
                onClick: () => {}
              },
              {
                text: 'Delete',
                onClick: () => {},
                cls: 'danger'
              }
            ]"
          >
            <template #content>
              <div
                class="w-100 d-flex align-items-center justify-content-between"
              >
                <div class="flex-2">
                  <TableCell size="xxl">
                    <strong class="xl"> <b>XS credit package</b></strong>
                  </TableCell>
                </div>

                <div
                  class="flex-1 d-flex align-items-center justify-content-end w-100"
                >
                  <TableCell size="sm">$3.50</TableCell>
                  <TableCell size="md">$5.00</TableCell>
                  <TableCell size="smL">Active</TableCell>
                </div>
              </div>
            </template>
          </TableRow>
        </template>
        <template #table-footer>
          <TableFooter :use-select="false" count-text="6 credit packages" />
        </template>
      </TableView>
    </div>
  </div>
</template>

<script>
import PageMainHeading from "@/components/Layout/PageMainHeading";
import Btn from "@/components/ui/Buttons/Button";
import BtnContent from "@/components/ui/Buttons/BtnContent";
import TableView from "@/components/Table/TableView";
import TableHeadCell from "@/components/Table/TableHeadCell";
import TableCell from "@/components/Table/TableCell";
import TableRow from "@/components/Table/TableRow";
import TableFooter from "@/components/Table/TableFooter";
import Dialog from "@/components/ui/Modal/Dialog/Dialog";
import AppInput from "@/components/Form/AppInput";
import AppCheckbox from "@/components/Form/AppCheckbox";
export default {
  name: "CreditPacks",
  components: {
    AppCheckbox,
    AppInput,
    TableFooter,
    TableRow,
    TableCell,
    TableHeadCell,
    TableView,
    BtnContent,
    Btn,
    PageMainHeading,
    Dialog
  },
  data() {
    return {
      addCreditPackModalOpened: false,
      newPackageTitle: "",
      newPackagePrice: "",
      newPackageCredits: "",
      newPackageActive: false
    };
  }
};
</script>

<style scoped></style>
