<template>
  <div class="page-content-heading">
    <div class="row align-items-center">
      <div class="col-3">
        <h1>{{ title }}</h1>
      </div>
      <div class="col-9">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageMainHeading",
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.page-content-heading {
  padding-bottom: 20px;
  position: relative;
  z-index: 2;

  h1 {
    text-transform: capitalize;
  }

  .select-dropdown {
    width: 190px;
  }
  .search-control {
    width: 260px;
    max-width: 100%;
    overflow: hidden;
  }
}

.page-heading-toolbar {
  display: flex;
  align-items: center;
  &--col {
    padding-left: 1em;
  }
}
</style>
